import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { useCrossSellInterstitialToggle } from '@ecomm/feature-toggle/CrossSellInterstitial';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';

export const useCartResponse = () => {
  const { errorReporter } = useErrorReporter();
  const { CTCartEnabled } = useMigrationStatus();
  const { isExperimentActive, activeVariation } = useCrossSellInterstitialToggle();
  const ctCartResponse = useGetCartItems();
  const legacyCartResponse = useCartWithVariables({
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });
  const isCrossSellInterstitialEnabled = isExperimentActive && activeVariation === 'on';
  const ctCart = ctCartResponse?.data;
  const legacyCart = legacyCartResponse?.data?.cart;
  const isCTCart = CTCartEnabled && !Boolean(legacyCart?.numberOfItems);

  return {
    isValidCartResponse: isCrossSellInterstitialEnabled && Boolean(ctCart || legacyCart),
    cart: isCTCart ? ctCart : legacyCart,
    isCTCart,
  };
};
